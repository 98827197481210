var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "form-data",
            {
              ref: "weldlogReference",
              attrs: { references: _vm.formReferences, model: _vm.modelObj },
              on: {
                "update:references": function ($event) {
                  _vm.formReferences = $event
                },
              },
            },
            [
              _vm.modelObj.id && _vm.listOfDocuments.length > 0
                ? _c(
                    "template",
                    { slot: "weldlog_documents" },
                    [
                      _c("documents", {
                        attrs: {
                          documents: _vm.listOfDocuments,
                          deleteIds: _vm.deleteDocumentIds,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "template",
                { slot: "weld_end_complete" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-0 py-1", attrs: { cols: "2" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "justify-center align-center d-flex",
                              attrs: {
                                elevation: "0",
                                width: "100%",
                                height: "100%",
                                outlined: "",
                              },
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: { "hide-details": "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.setWeldEnd("weld_end")
                                  },
                                },
                                model: {
                                  value: _vm.modelObj.is_completed,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelObj, "is_completed", $$v)
                                  },
                                  expression: "modelObj.is_completed",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-1 px-0", attrs: { cols: "10" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "0" } },
                            [
                              _c("v-text-field", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  readonly: "",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  label: _vm.$t(
                                    "message.projectFeature.weldEnd"
                                  ),
                                },
                                model: {
                                  value: _vm.modelObj.weld_end,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelObj, "weld_end", $$v)
                                  },
                                  expression: "modelObj.weld_end",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "warehouse_product_batchno" }, [
                _c(
                  "div",
                  { staticClass: "custom_border" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "12" } },
                          [
                            _c("v-simple-table", {
                              attrs: { dense: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              attrs: { width: "50%" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "message.projectFeature.productNumber"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              attrs: { width: "50%" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "message.projectFeature.batchNr"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.warehouseProductBatches,
                      function (productBatch, index) {
                        return _c(
                          "v-row",
                          { key: index, staticClass: "mx-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-1", attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.listOfProducts,
                                    "item-text": function (val) {
                                      return "" + (val.product_full_name || "")
                                    },
                                    "item-value": "product_id",
                                    "hide-details": "",
                                    outlined: "",
                                    label: _vm.$t(
                                      "message.projectFeature.choose"
                                    ),
                                    dense: "",
                                    rules: [],
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getBatchByMaterials(
                                        index,
                                        +productBatch.product_id
                                      )
                                    },
                                  },
                                  model: {
                                    value: productBatch.product_id,
                                    callback: function ($$v) {
                                      _vm.$set(productBatch, "product_id", $$v)
                                    },
                                    expression: "productBatch.product_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pa-1", attrs: { sm: "5" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    loading: productBatch.batchLoading,
                                    disabled:
                                      productBatch.listOfBatches.length < 1,
                                    items: productBatch.listOfBatches,
                                    "item-text": "name",
                                    "item-value": "_id",
                                    "hide-details": "",
                                    outlined: "",
                                    label: _vm.$t(
                                      "message.projectFeature.choose"
                                    ),
                                    dense: "",
                                    rules: [],
                                  },
                                  model: {
                                    value: productBatch.batch_no,
                                    callback: function ($$v) {
                                      _vm.$set(productBatch, "batch_no", $$v)
                                    },
                                    expression: "productBatch.batch_no",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pa-1", attrs: { sm: "1" } },
                              [
                                _vm.warehouseProductBatches &&
                                _vm.warehouseProductBatches.length > 1
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-1",
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeBatchRow(index)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-minus")])],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-1",
                            staticStyle: { float: "right" },
                            attrs: { fab: "", "x-small": "", color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.scanQrCode()
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-qrcode-scan")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-1",
                            staticStyle: { float: "right" },
                            attrs: { fab: "", "x-small": "", color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addNewProductBatchObj()
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-plus")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.scanQRDialog,
            callback: function ($$v) {
              _vm.scanQRDialog = $$v
            },
            expression: "scanQRDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title"),
              _c("v-spacer"),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "pickForm",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pr-0 pb-0 mt-1",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("barcode-scanner", {
                                attrs: {
                                  search: _vm.scanCode,
                                  scannerPayload: _vm.scannerPayload,
                                  loading: _vm.productLoading,
                                },
                                model: {
                                  value: _vm.scanCode,
                                  callback: function ($$v) {
                                    _vm.scanCode = $$v
                                  },
                                  expression: "scanCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.productFound
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pt-1 pb-1 font-weight-bold",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("message.equipments.product")
                                      ) +
                                      ": " +
                                      _vm._s(
                                        _vm.productInfo.description || ""
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.productFound && _vm.productInfo.isbatch
                            ? [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-0 pb-0",
                                    attrs: { cols: "7" },
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      ref: "batchRef",
                                      attrs: {
                                        items: _vm.getbatch,
                                        "item-text": "batchnumberpwa",
                                        "item-value": "batchnumberpwa",
                                        "hide-details": "",
                                        outlined: "",
                                        loading: _vm.loadingBatch,
                                        label: _vm.$t("message.product.batch"),
                                        flat: "",
                                        autocomplete: "off",
                                        dense: "",
                                        rules: _vm.$_requiredValidation,
                                      },
                                      on: { change: _vm.checkTheBatchnumber },
                                      model: {
                                        value: _vm.selectBatch,
                                        callback: function ($$v) {
                                          _vm.selectBatch = $$v
                                        },
                                        expression: "selectBatch",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.moreLocationsFound
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "pl-0 pt-0 pb-0",
                                        attrs: { cols: "5" },
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          ref: "moreLocation",
                                          attrs: {
                                            items: _vm.batchLocations,
                                            "item-text": _vm.getItemText,
                                            "item-value": "locationname",
                                            "hide-details": "",
                                            outlined: "",
                                            loading: _vm.loadingLocation,
                                            label: _vm.$t(
                                              "message.product.scanLoc"
                                            ),
                                            flat: "",
                                            autocomplete: "off",
                                            dense: "",
                                            rules: _vm.$_requiredValidation,
                                          },
                                          on: {
                                            change: _vm.checkIfLocationValid,
                                          },
                                          model: {
                                            value: _vm.selectedLocation,
                                            callback: function ($$v) {
                                              _vm.selectedLocation = $$v
                                            },
                                            expression: "selectedLocation",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm.productFound && !_vm.productInfo.isbatch
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "8" },
                                },
                                [
                                  _c("v-autocomplete", {
                                    ref: "moreLocation",
                                    attrs: {
                                      items: _vm.listOfLocations,
                                      "item-text": _vm.getItemText,
                                      "item-value": "locationname",
                                      "hide-details": "",
                                      outlined: "",
                                      loading: _vm.loadingLocation,
                                      label: _vm.$t("message.product.scanLoc"),
                                      flat: "",
                                      autocomplete: "off",
                                      dense: "",
                                      rules: _vm.$_requiredValidation,
                                    },
                                    on: { change: _vm.checkIfLocationValid },
                                    model: {
                                      value: _vm.selectedLocation,
                                      callback: function ($$v) {
                                        _vm.selectedLocation = $$v
                                      },
                                      expression: "selectedLocation",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.productFound
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-1", attrs: { cols: "9" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center pa-0",
                                          attrs: { cols: "3" },
                                          on: { click: _vm.decreaseQty },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "info",
                                                fab: "",
                                                "x-small": "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-minus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "qtyRef",
                                            staticClass: "text-right",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "",
                                              type: "number",
                                              autocomplete: "off",
                                              rules: _vm.$_qtyValidation,
                                              placeholder: _vm.$t(
                                                "message.product.qty"
                                              ),
                                            },
                                            model: {
                                              value: _vm.pickQty,
                                              callback: function ($$v) {
                                                _vm.pickQty = $$v
                                              },
                                              expression: "pickQty",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center pa-0",
                                          attrs: { cols: "3" },
                                          on: { click: _vm.increaseQty },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "info",
                                                fab: "",
                                                "x-small": "",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-1 mt-1",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        dark: "",
                                        small: "",
                                        loading: _vm.saveLoading,
                                      },
                                      on: { click: _vm.pickQtyOnOrder },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("message.order.pick")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      on: { click: _vm.cancelScanQRDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }